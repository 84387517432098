// App.js
import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid'; // Importation de uuid pour générer des identifiants uniques
import Sidebar from './components/Sidebar/Sidebar';
import ChatWindow from './components/ChatWindow/ChatWindow';
import './App.css';
const App = () => {
  const [messages, setMessages] = useState([]);
  const [hasVisited, setHasVisited] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [showWelcome, setShowWelcome] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [userId, setUserId] = useState(null); // État pour stocker l'identifiant de l'utilisateur
  const [conversationId, setConversationId] = useState(null); // État pour stocker l'identifiant de conversation
  useEffect(() => {
    // Générer ou récupérer l'userId
    let storedUserId = localStorage.getItem('userId');
    if (!storedUserId) {
      storedUserId = uuidv4();
      localStorage.setItem('userId', storedUserId);
    }
    setUserId(storedUserId);
    // Générer ou récupérer le conversationId
    let storedConversationId = localStorage.getItem('conversationId');
    if (!storedConversationId) {
      storedConversationId = uuidv4();
      localStorage.setItem('conversationId', storedConversationId);
    }
    setConversationId(storedConversationId);
    // Vérifier si l'utilisateur a déjà visité
    const visited = localStorage.getItem('hasVisited');
    if (visited) {
      setHasVisited(true);
      setShowWelcome(false); // Ne pas afficher le message de bienvenue pour les utilisateurs de retour
    } else {
      localStorage.setItem('hasVisited', 'true');
    }
    // Charger les messages précédents depuis le backend
    const fetchMessages = async () => {
      try {
        const response = await fetch('https://backend-vybuddy.vygeek.com/get_messages', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ user_id: storedUserId, conversation_id: storedConversationId }),
        });
        const data = await response.json();
        const loadedMessages = data.messages.map((msg, index) => ({
          id: msg._id || index,
          role: msg.role,
          content: msg.content,
          isNew: false,
        }));
        setMessages(loadedMessages);
        if (loadedMessages.length > 0) {
          setShowWelcome(false);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des messages :', error);
      }
    };
    fetchMessages();
    // Charger la préférence du mode sombre
    const darkModePreference = localStorage.getItem('isDarkMode');
    if (darkModePreference !== null) {
      setIsDarkMode(darkModePreference === 'true');
    }
  }, []);
  useEffect(() => {
    // Sauvegarder la préférence du mode sombre
    localStorage.setItem('isDarkMode', isDarkMode);
  }, [isDarkMode]);
  const handleSendMessage = async (message) => {
    // Générer un identifiant unique pour le message
    const messageId = Date.now();
    // Ajouter le message de l'utilisateur
    const newMessages = [
      ...messages,
      { id: messageId, role: 'user', content: message, isNew: true },
    ];
    setMessages(newMessages);
    // Appeler l'API backend
    try {
      const response = await fetch('https://backend-vybuddy.vygeek.com/chat', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ input: message, user_id: userId, conversation_id: conversationId }),
      });
      const data = await response.json();
      // Générer un identifiant unique pour la réponse de l'assistant
      const assistantMessageId = Date.now() + 1;
      // Ajouter la réponse de l'assistant
      const updatedMessages = [
        ...newMessages,
        {
          id: assistantMessageId,
          role: 'assistant',
          content: data.output,
          isNew: true,
        },
      ];
      setMessages(updatedMessages);
    } catch (error) {
      console.error("Erreur lors de l'appel à l'API chat :", error);
    }
  };
  const handleClearChat = () => {
    setMessages([]);
    // Générer un nouveau conversationId
    const newConversationId = uuidv4();
    setConversationId(newConversationId);
    localStorage.setItem('conversationId', newConversationId);
    setShowWelcome(true); // Afficher le message de bienvenue lors de la réinitialisation du chat
  };
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <div className={`app ${isDarkMode ? 'dark-mode' : ''}`}>
      <Sidebar
        onClearChat={handleClearChat}
        isOpen={isSidebarOpen}
        onToggle={toggleSidebar}
        isDarkMode={isDarkMode}
        setIsDarkMode={setIsDarkMode}
      />
      <ChatWindow
        messages={messages}
        setMessages={setMessages}
        onSendMessage={handleSendMessage}
        isSidebarOpen={isSidebarOpen}
        showWelcome={showWelcome}
        setShowWelcome={setShowWelcome}
        isDarkMode={isDarkMode}
      />
    </div>
  );
};
export default App;