//TypingMessage.js
import React, { useState, useEffect, useRef } from 'react';

const parseMarkdown = (text) => {
  if (typeof text !== 'string') {
    return [];
  }

  const lines = text.split('\n');
  let inList = false;
  let listItems = [];

  return lines.map((line, lineIndex) => {
    // Gestion des titres et du texte en gras
    const parts = line.split(/(\*\*[^*]+\*\*|\#{2,3} .+)/g);
    const formattedParts = parts.map((part, partIndex) => {
      if (part.startsWith('**') && part.endsWith('**')) {
        return <strong key={`${lineIndex}-${partIndex}`}>{part.slice(2, -2)}</strong>;
      } else if (part.startsWith('### ')) {
        return <h3 key={`${lineIndex}-${partIndex}`}>{part.slice(4)}</h3>;
      } else if (part.startsWith('## ')) {
        return <h2 key={`${lineIndex}-${partIndex}`}>{part.slice(3)}</h2>;
      }
      return part;
    });

    // Gestion des listes
    if (line.trim().startsWith('- ') || line.trim().startsWith('* -')) {
      if (!inList) {
        inList = true;
        listItems = [];
      }
      const listItemContent = line.trim().startsWith('* -')
        ? line.slice(3).trim()
        : line.slice(1).trim();
      listItems.push(<li key={`list-item-${lineIndex}`}>{listItemContent}</li>);
      return null;
    } else if (inList) {
      const list = <ul key={`list-${lineIndex}`}>{listItems}</ul>;
      inList = false;
      listItems = [];
      return list;
    }

    return <p key={`line-${lineIndex}`}>{formattedParts}</p>;
  }).filter(item => item !== null);
};

const cleanText = (text) => {
  return text;
};

const TypingMessage = ({ text }) => {
  const [displayText, setDisplayText] = useState('');
  const [isTyping, setIsTyping] = useState(true);
  const typingIntervalRef = useRef(null);

  useEffect(() => {
    let currentIndex = 0;
    const words = text.split(' ');

    typingIntervalRef.current = setInterval(() => {
      if (currentIndex < words.length) {
        setDisplayText((prevText) => prevText + words[currentIndex] + ' ');
        currentIndex++;
      } else {
        clearInterval(typingIntervalRef.current);
        setIsTyping(false);
      }
    }, 50); // Adjust the interval as needed

    return () => clearInterval(typingIntervalRef.current);
  }, [text]);

  useEffect(() => {
    if (!isTyping) {
      setDisplayText(text); // Ensure the full text is displayed once typing is complete
    }
  }, [isTyping, text]);

  return <div>{parseMarkdown(cleanText(displayText))}</div>; // Clean and parse Markdown for the typed text
};

export default TypingMessage;
