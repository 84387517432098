import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './MessageFeedbackModal.css';

const MessageFeedbackModal = ({ isOpen, onClose, onSubmit }) => {
  const [feedbackText, setFeedbackText] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = () => {
    onSubmit(feedbackText);
    setFeedbackText('');
    setIsSubmitted(true);

    setTimeout(() => {
      setIsSubmitted(false);
      onClose();
    }, 2000);
  };

  if (!isOpen) return null;

  const modalContent = (
    <div className="message-feedback-modal-overlay">
      <div className="message-feedback-modal">
        {isSubmitted ? (
          <h3>Votre feedback a été envoyé avec succès !</h3>
        ) : (
          <>
            <h2>Feedback</h2>
            <p className="details-text">Veuillez fournir des détails : </p>
            <textarea
              value={feedbackText}
              onChange={(e) => setFeedbackText(e.target.value)}
              placeholder="Qu'est-ce qui était satisfaisant dans cette réponse ?"
              className="feedback-textarea"
            />
            <p className="disclaimer-text">
            La soumission de ce feedback enverra la conversation actuelle à VyPerf et la stockera avec vos commentaires pour améliorer nos modèles à l'avenir. Lorsque vous soumettez des commentaires, nous améliorerons les réponses de VyBuddy afin d'être plus performant et mieux répondre aux questions des utilisateurs.
            </p>
            <div className="modal-buttons">
              <button className="cancel-button" onClick={onClose}>
                Annuler
              </button>
              <button className="submit-button" onClick={handleSubmit}>
                Soumettre
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );

  return ReactDOM.createPortal(modalContent, document.body);
};

export default MessageFeedbackModal;