// ChatWindow.js
import React, { useState, useRef, useEffect, useMemo } from 'react';
import './ChatWindow.css';
import sendIcon from '../../assets/send-icon.png';
import greySendIcon from '../../assets/grey-send-icon.png';

// Import the Message component
import Message from '../Message/Message';

// Import the general FeedbackModal component
import FeedbackModal from '../FeedbackModal/FeedbackModal';

const parseMarkdown = (text) => {
  // Existing code...
  if (typeof text !== 'string') {
    return [];
  }
  const lines = text.split('\n');
  let result = [];
  let currentList = null;
  lines.forEach((line, lineIndex) => {
    const trimmedLine = line.trim();
    if (trimmedLine.startsWith('- ') || trimmedLine.startsWith('* ')) {
      if (!currentList) {
        currentList = [];
      }
      currentList.push(
        <li key={`list-item-${lineIndex}`}>{parseLine(trimmedLine.slice(2))}</li>
      );
    } else {
      if (currentList) {
        result.push(<ul key={`list-${lineIndex}`}>{currentList}</ul>);
        currentList = null;
      }
      result.push(<p key={`line-${lineIndex}`}>{parseLine(line)}</p>);
    }
  });
  if (currentList) {
    result.push(<ul key={`list-final`}>{currentList}</ul>);
  }
  return result;
};

const parseLine = (line) => {
  const parts = line.split(/(\*\*[^*]+\*\*|\#{2,3} .+)/g);
  return parts.map((part, index) => {
    if (part.startsWith('**') && part.endsWith('**')) {
      return <strong key={index}>{part.slice(2, -2)}</strong>;
    } else if (part.startsWith('### ')) {
      return <h3 key={index}>{part.slice(4)}</h3>;
    } else if (part.startsWith('## ')) {
      return <h2 key={index}>{part.slice(3)}</h2>;
    }
    return part;
  });
};

const ChatWindow = ({
  messages = [],
  setMessages,
  onSendMessage,
  isSidebarOpen,
  showWelcome,
  setShowWelcome,
  isDarkMode // Passed isDarkMode prop for dark mode handling
}) => {
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false); // General feedback modal state
  const messagesEndRef = useRef(null);
  const animatedMessageRef = useRef(null);
  const suggestions = useMemo(
    () => [
      "Durant mon préavis, ai-je droit à des jours de congés ?",
      "Quelles sont les fonctionnalités de Monday.com ?",
      "Qu'est ce qui pourrait prolonger la période d'essai ?",
      "Pourquoi mon salaire net peut être différent d'un mois à un autre ?",
    ],
    []
  );

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(scrollToBottom, [messages]);

  useEffect(() => {
    if (showWelcome && animatedMessageRef.current) {
      const welcomeMessage =
        "Bonjour ! Je suis VyBuddy. Comment puis-je vous aider aujourd'hui ?";
      const words = welcomeMessage.trim().split(' ');
      let currentWord = 0;
      let message = '';
      const timer = setInterval(() => {
        if (currentWord < words.length) {
          message += words[currentWord] + ' ';
          animatedMessageRef.current.innerText = message;
          currentWord++;
        } else {
          clearInterval(timer);
        }
      }, 50);
      return () => clearInterval(timer);
    }
  }, [showWelcome]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (input.trim()) {
      setIsLoading(true);
      try {
        await onSendMessage(input);
        setInput('');
        setShowWelcome(false);
      } catch (error) {
        console.error('Error sending message:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSuggestionClick = async (suggestion) => {
    setIsLoading(true);
    try {
      await onSendMessage(suggestion);
      setShowWelcome(false);
    } catch (error) {
      console.error('Error sending suggestion:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle general feedback submission
  const handleFeedbackSubmit = (feedbackData) => {
    // Handle the feedback submission here
    console.log('Feedback submitted:', feedbackData);
    // You can send this data to your server or perform any other action
    setIsFeedbackModalOpen(false);
  };

  const renderMessages = () => {
    if (!Array.isArray(messages)) {
      console.error('Messages is not an array:', messages);
      return null;
    }
    return messages.map((msg) => (
      <Message
        key={msg.id}
        msg={msg}
        messages={messages}
        setMessages={setMessages}
        parseMarkdown={parseMarkdown}
      />
    ));
  };

  return (
    <div className={`chat-window ${isSidebarOpen ? '' : 'sidebar-closed'}`}>
      <div className="messages">
        {showWelcome ? (
          <div className="welcome-message">
            <div className="message assistant">
              <div>
                <h3 className="text-vybuddy" ref={animatedMessageRef}></h3>
                <div className="suggestions-container">
                  <div className="suggestions-title">Suggestions :</div>
                  {suggestions.map((suggestion, index) => (
                    <button
                      key={index}
                      onClick={() => handleSuggestionClick(suggestion)}
                      className="suggestion-button"
                    >
                      {suggestion}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          renderMessages()
        )}
        {isLoading && <div className="loader"></div>}
        <div ref={messagesEndRef} />
      </div>
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Parlez avec VyBuddy ..."
            disabled={isFeedbackModalOpen}  // Disable input when feedback modal is open
          />
          <button
            type="submit"
            className="send-button"
            disabled={isLoading || !input.trim() || isFeedbackModalOpen}  // Disable the send button when feedback modal is open
          >
            <img
              src={input.trim() ? sendIcon : greySendIcon}
              alt="Send"
              className="send-icon"
            />
          </button>
        </div>
      </form>

      <div className="reserve-clause">
        VyBuddy peut afficher des informations inexactes, y compris sur des personnes. Vérifiez donc ses réponses.
      </div>
      {/* General Feedback Button */}
      <button
        className={`feedback-button ${isDarkMode ? 'dark-mode' : ''}`} // Dark mode class toggle
        onClick={() => setIsFeedbackModalOpen(true)}
      >
        Feedback
      </button>
      {/* General Feedback Modal */}
      <FeedbackModal
        isOpen={isFeedbackModalOpen}
        onClose={() => setIsFeedbackModalOpen(false)}
        onSubmit={handleFeedbackSubmit}
      />
    </div>
  );
};

export default ChatWindow;
