// App.js
import React, { useState, useEffect } from 'react';
import Sidebar from './components/Sidebar/Sidebar';
import ChatWindow from './components/ChatWindow/ChatWindow';
import './App.css';

const App = () => {
  const [messages, setMessages] = useState([]);
  const [hasVisited, setHasVisited] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [showWelcome, setShowWelcome] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    // Check if user has visited before
    const visited = localStorage.getItem('hasVisited');
    if (visited) {
      setHasVisited(true);
      setShowWelcome(false); // Don't show welcome for returning users
    } else {
      localStorage.setItem('hasVisited', 'true');
    }

    // Load previous messages
    const savedMessages = localStorage.getItem('chatMessages');
    if (savedMessages) {
      const loadedMessages = JSON.parse(savedMessages).map((msg, index) => ({
        ...msg,
        isNew: false,
        id: msg.id || index,
      }));
      setMessages(loadedMessages);
      setShowWelcome(false); // Don't show welcome if there are saved messages
    }

    // Load dark mode preference
    const darkModePreference = localStorage.getItem('isDarkMode');
    if (darkModePreference !== null) {
      setIsDarkMode(darkModePreference === 'true');
    }
  }, []);

  useEffect(() => {
    // Save dark mode preference
    localStorage.setItem('isDarkMode', isDarkMode);
  }, [isDarkMode]);

  const handleSendMessage = async (message) => {
    // Generate a unique id for the message
    const messageId = Date.now();

    // Add user message
    const newMessages = [
      ...messages,
      { id: messageId, role: 'user', content: message, isNew: true },
    ];
    setMessages(newMessages);

    // Call backend API (replace with your actual API endpoint)
    try {
      const response = await fetch('https://backend-vybuddy.vygeek.com/chat', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ input: message }),
      });
      const data = await response.json();

      // Generate a unique id for the assistant's response
      const assistantMessageId = Date.now() + 1;

      // Add assistant response
      const updatedMessages = [
        ...newMessages,
        {
          id: assistantMessageId,
          role: 'assistant',
          content: data.output,
          isNew: true,
        },
      ];
      setMessages(updatedMessages);

      // Save messages to local storage
      localStorage.setItem('chatMessages', JSON.stringify(updatedMessages));
    } catch (error) {
      console.error('Error calling chat API:', error);
    }
  };

  const handleClearChat = () => {
    setMessages([]);
    localStorage.removeItem('chatMessages');
    setShowWelcome(true); // Show welcome message when clearing chat
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className={`app ${isDarkMode ? 'dark-mode' : ''}`}>
      <Sidebar
        onClearChat={handleClearChat}
        isOpen={isSidebarOpen}
        onToggle={toggleSidebar}
        isDarkMode={isDarkMode}
        setIsDarkMode={setIsDarkMode}
      />
      <ChatWindow
        messages={messages}
        setMessages={setMessages}
        onSendMessage={handleSendMessage}
        isSidebarOpen={isSidebarOpen}
        showWelcome={showWelcome}
        setShowWelcome={setShowWelcome}
        isDarkMode={isDarkMode}
      />
    </div>
  );
};

export default App;
