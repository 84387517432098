// Sidebar.js
import React from 'react';
import './Sidebar.css';
import lottieFile from '../../assets/chat_lottie.json';
import Lottie from 'react-lottie';
import arrowIcon from '../../assets/arrow-icon.png';
import logoVybuddy from '../../assets/logo-vybuddy.png';

const Sidebar = ({ onClearChat, isOpen, onToggle, isDarkMode, setIsDarkMode }) => {
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: lottieFile,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className={`sidebar ${isOpen ? '' : 'closed'}`}>
      <img
        src={arrowIcon}
        alt="Toggle"
        className={`arrow-icon ${isOpen ? 'rotate' : ''}`}
        onClick={onToggle}
      />
      <img src={logoVybuddy} alt="VyBuddy" className="sidebar-logo" />
      <Lottie options={lottieOptions} height={200} width={200} />
      <div className="about-section">
        <h2 className="about-title">À propos de VyBuddy</h2>
        <p className="about-description">
          VyBuddy est votre assistant virtuel intelligent, prêt à vous aider en vous fournissant des informations sur les procédures RH ainsi qu'une assistance technique de base etc.
        </p>
      </div>
      <div className="links">
        <a href="https://forms.monday.com/forms/98071d93858ef6458a158c464d74c192?r=use1" className="link">
          Créer un ticket helpdesk
        </a>
        <a href="https://virtuology.monday.com" className="link">Aller sur Monday.com</a>
      </div>
      <button onClick={onClearChat} className="clear-chat-button">Nouvelle conversation</button>
      
      {/* Dark Mode Toggle */}
      <div className="dark-mode-toggle">
        <label className="switch">
          <input type="checkbox" checked={isDarkMode} onChange={() => setIsDarkMode(!isDarkMode)} />
          <span className="slider round"></span>
        </label>
        <span className="dark-mode-label">{isDarkMode ? 'Mode Clair' : 'Mode Sombre'}</span>
      </div>
    </div>
  );
};

export default Sidebar;
