import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import './FeedbackModal.css';

const emojis = [
  { icon: '😡', score: 1 },
  { icon: '😕', score: 2 },
  { icon: '😐', score: 3 },
  { icon: '🙂', score: 4 },
  { icon: '😄', score: 5 }
];

const FeedbackModal = ({ isOpen, onClose }) => {
  const [rating, setRating] = useState(null);
  const [comment, setComment] = useState('');
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    if (rating === null) return;

    setIsSubmitting(true);
    try {
      await axios.post('https://backend-vybuddy.vygeek.com/feedback', {
        rating: rating.score,
        comment,
        email: email || undefined
      });
      onClose();
    } catch (error) {
      console.error('Error submitting feedback:', error);
      // Handle error (e.g., show error message to user)
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div 
          className="modal-overlay"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div 
            className="modal-content"
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
          >
            <div className="modal-header">
              <h2>Comment évalueriez-vous votre expérience ?</h2>
              <button className="close-button" onClick={onClose}>
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="rating-buttons">
                {emojis.map((emoji) => (
                  <motion.button
                    key={emoji.score}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => setRating(emoji)}
                    className={`rating-button ${rating?.score === emoji.score ? 'selected' : ''}`}
                  >
                    <span className={`emoji ${rating && rating.score !== emoji.score ? 'faded' : ''}`}>
                      {emoji.icon}
                    </span>
                  </motion.button>
                ))}
              </div>
              {rating && (
                <>
                  <textarea
                    placeholder="Faites-nous part de votre expérience"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    className="comment-textarea"
                  />
                  <input
                    type="email"
                    placeholder="Votre adresse email (facultatif)"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="email-input"
                  />
                </>
              )}
            </div>
            <div className="modal-footer">
              <button className="cancel-button" onClick={onClose}>Annuler</button>
              <button 
                className="submit-button" 
                onClick={handleSubmit} 
                disabled={rating === null || isSubmitting}
              >
                {isSubmitting ? 'Envoi...' : 'Envoyer'}
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default FeedbackModal;