// Message.js
import React, { useState, useRef, useEffect } from 'react';
import './Message.css';
import userIcon from '../../assets/user-icon.png';
import assistantIcon from '../../assets/assistant-icon.png';
import TypingMessage from '../TypingMessage/TypingMessage';

// Import des icônes de réaction et d'action
import addReactionIcon from '../../assets/add-reaction-icon.png';
import giveFeedbackIcon from '../../assets/give-feedback-icon.png';
import thumbsUpEmoji from '../../assets/thumbs-up-emoji.png';
import thumbsDownEmoji from '../../assets/thumbs-down-emoji.png';

// Import du composant MessageFeedbackModal
import MessageFeedbackModal from '../MessageFeedbackModal/MessageFeedbackModal';

const Message = ({ msg, messages, setMessages, parseMarkdown }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [showReactionPicker, setShowReactionPicker] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showFeedbackSentPopup, setShowFeedbackSentPopup] = useState(false); // Nouvel état pour le popup de feedback

  // Référence pour détecter les clics extérieurs du "reaction-picker"
  const reactionPickerRef = useRef(null);

  // Timeout pour cacher le reaction picker après 30 secondes
  useEffect(() => {
    if (showReactionPicker) {
      const hideTimeout = setTimeout(() => {
        setShowReactionPicker(false);
      }, 30000); // Cache après 30 secondes

      return () => clearTimeout(hideTimeout); // Nettoyage si le composant est démonté
    }
  }, [showReactionPicker]);

  // Fermer le reaction picker quand l'utilisateur clique en dehors
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (reactionPickerRef.current && !reactionPickerRef.current.contains(event.target)) {
        setShowReactionPicker(false); // Cache le reaction picker si on clique à l'extérieur
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside); // Nettoyage lors du démontage
    };
  }, []);

  // Fonction pour envoyer les données de réaction au backend
  const sendReaction = async (messageId, reactionLabel, messageContent) => {
    const requestBody = {
      messageId: String(messageId), // S'assurer que l'ID est une chaîne de caractères
      reaction: reactionLabel,
      message: messageContent,
    };

    console.log('Envoi de la réaction :', requestBody);

    try {
      const response = await fetch('https://backend-vybuddy.vygeek.com/message_reaction', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      });
      if (!response.ok) {
        throw new Error("Échec de l'envoi de la réaction");
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi de la réaction :", error);
    }
  };

  // Fonction pour envoyer les données de feedback au backend
  const sendFeedback = async (messageId, feedbackText, messageContent) => {
    const requestBody = {
      messageId: String(messageId),
      feedback: feedbackText,
      message: messageContent,
    };

    console.log('Envoi du feedback :', requestBody);

    try {
      const response = await fetch('https://backend-vybuddy.vygeek.com/message_feedback', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      });
      if (!response.ok) {
        throw new Error("Échec de l'envoi du feedback");
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi du feedback :", error);
    }
  };

  // Fonction pour mettre à jour un message dans le tableau des messages
  const updateMessage = (messageId, updates) => {
    setMessages((prevMessages) => {
      const updatedMessages = prevMessages.map((msg) =>
        msg.id === messageId ? { ...msg, ...updates } : msg
      );
      // Sauvegarder les messages dans le stockage local
      localStorage.setItem('chatMessages', JSON.stringify(updatedMessages));
      return updatedMessages;
    });
  };

  const handleReactionClick = () => {
    setShowReactionPicker(!showReactionPicker);
  };

  const handleSelectReaction = (emoji, label) => {
    // Mettre à jour le message avec la réaction sélectionnée
    updateMessage(msg.id, { reaction: { emoji, label } });
    setShowReactionPicker(false);
    // Envoyer la réaction au backend
    sendReaction(msg.id, label, msg.content);
  };

  const handleFeedbackClick = () => {
    setShowFeedbackModal(true);
  };

  const handleFeedbackSubmit = (feedbackText) => {
    // Afficher le message "Feedback envoyé"
    setShowFeedbackSentPopup(true);

    // Masquer le popup après 3 secondes
    setTimeout(() => {
      setShowFeedbackSentPopup(false);
    }, 3000);

    // Envoyer le feedback au backend
    sendFeedback(msg.id, feedbackText, msg.content);
  };

  const reaction = msg.reaction;

  return (
    <div
      className={`message ${msg.role}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={msg.role === 'user' ? userIcon : assistantIcon}
        alt={msg.role}
        className="message-icon"
      />
      <div className="message-content-container">
        <div className="message-content">
          {msg.role === 'assistant' && msg.isNew ? (
            <TypingMessage text={msg.content} />
          ) : (
            parseMarkdown(msg.content)
          )}
        </div>
        {reaction && (
          <div className="reaction-container">
            <img src={reaction.emoji} alt={reaction.label} className="reaction-emoji" />
          </div>
        )}
      </div>
      {isHovered && (
        <div className="message-options">
          <button
            className="option-button"
            onClick={handleReactionClick}
            title="Ajouter une réaction"
          >
            <img src={addReactionIcon} alt="Ajouter une réaction" />
          </button>
          <button
            className="option-button"
            onClick={handleFeedbackClick}
            title="Envoyer un feedback"
          >
            <img src={giveFeedbackIcon} alt="Envoyer un feedback" />
          </button>
        </div>
      )}
      {showReactionPicker && (
        <div ref={reactionPickerRef} className="reaction-picker">
          <button onClick={() => handleSelectReaction(thumbsUpEmoji, 'like')}>
            <img src={thumbsUpEmoji} alt="Like" />
          </button>
          <button onClick={() => handleSelectReaction(thumbsDownEmoji, 'dislike')}>
            <img src={thumbsDownEmoji} alt="Dislike" />
          </button>
        </div>
      )}
      {showFeedbackModal && (
        <MessageFeedbackModal
          isOpen={showFeedbackModal}
          onClose={() => setShowFeedbackModal(false)}
          onSubmit={handleFeedbackSubmit}
        />
      )}
    </div>
  );
};

export default Message;
