// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* App.css */
.app {
  display: flex;
  height: 100vh;
  /*overflow: hidden;*/
}

/* HTML: <div class="loader"></div> */
.loader {
  width: fit-content;
  font-weight: bold;
  font-family: monospace;
  font-size: 20px;
  color :#0000;
  overflow: hidden;
  animation: l9 5s infinite cubic-bezier(0.3,1,0,1);
}
.loader:before {
  content:"Thinking..."
}
@keyframes l9 {
  0%  {text-shadow: 0    0 #777777, 11ch 0 #93b13d, 22ch 0 #1796dd, 33ch 0 #efc233,44ch 0 #777777}
  25% {text-shadow:-11ch 0 #777777,  0ch 0 #93b13d, 11ch 0 #1796dd, 22ch 0 #efc233,33ch 0 #777777}
  50% {text-shadow:-22ch 0 #777777,-11ch 0 #93b13d,  0ch 0 #1796dd, 11ch 0 #efc233,22ch 0 #777777}
  75% {text-shadow:-33ch 0 #777777,-22ch 0 #93b13d,-11ch 0 #1796dd,  0ch 0 #efc233,11ch 0 #777777}
  100%{text-shadow:-44ch 0 #777777,-33ch 0 #93b13d,-22ch 0 #1796dd,-11ch 0 #efc233, 0ch 0 #777777}
}


.app.dark-mode {
  background-color: #1e1e1e;
  color: #ffffff;
}

.app.dark-mode .chat-window {
  background-color: #1e1e1e;
  color: #ffffff;
}

.app.dark-mode .reserve-clause {
  background-color: #1e1e1e;
  color: #bbbbbb;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;EACE,aAAa;EACb,aAAa;EACb,oBAAoB;AACtB;;AAEA,qCAAqC;AACrC;EACE,kBAAkB;EAClB,iBAAiB;EACjB,sBAAsB;EACtB,eAAe;EACf,YAAY;EACZ,gBAAgB;EAChB,iDAAiD;AACnD;AACA;EACE;AACF;AACA;EACE,KAAK,0FAA0F;EAC/F,KAAK,0FAA0F;EAC/F,KAAK,0FAA0F;EAC/F,KAAK,0FAA0F;EAC/F,KAAK,0FAA0F;AACjG;;;AAGA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB","sourcesContent":["/* App.css */\n.app {\n  display: flex;\n  height: 100vh;\n  /*overflow: hidden;*/\n}\n\n/* HTML: <div class=\"loader\"></div> */\n.loader {\n  width: fit-content;\n  font-weight: bold;\n  font-family: monospace;\n  font-size: 20px;\n  color :#0000;\n  overflow: hidden;\n  animation: l9 5s infinite cubic-bezier(0.3,1,0,1);\n}\n.loader:before {\n  content:\"Thinking...\"\n}\n@keyframes l9 {\n  0%  {text-shadow: 0    0 #777777, 11ch 0 #93b13d, 22ch 0 #1796dd, 33ch 0 #efc233,44ch 0 #777777}\n  25% {text-shadow:-11ch 0 #777777,  0ch 0 #93b13d, 11ch 0 #1796dd, 22ch 0 #efc233,33ch 0 #777777}\n  50% {text-shadow:-22ch 0 #777777,-11ch 0 #93b13d,  0ch 0 #1796dd, 11ch 0 #efc233,22ch 0 #777777}\n  75% {text-shadow:-33ch 0 #777777,-22ch 0 #93b13d,-11ch 0 #1796dd,  0ch 0 #efc233,11ch 0 #777777}\n  100%{text-shadow:-44ch 0 #777777,-33ch 0 #93b13d,-22ch 0 #1796dd,-11ch 0 #efc233, 0ch 0 #777777}\n}\n\n\n.app.dark-mode {\n  background-color: #1e1e1e;\n  color: #ffffff;\n}\n\n.app.dark-mode .chat-window {\n  background-color: #1e1e1e;\n  color: #ffffff;\n}\n\n.app.dark-mode .reserve-clause {\n  background-color: #1e1e1e;\n  color: #bbbbbb;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
